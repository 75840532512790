/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout";
import WorkspaceList from "../components/WorkspaceList"

export const query = graphql`
  query WorkspaceQuery {
    allWorkspace {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`


// markup
const IndexPage = () => {
  const data = useStaticQuery(query)
  console.log(data)

  return (
    <Layout location="/">
      <h3>Popular topics</h3>
      <p>
        <WorkspaceList workspaces={data} />
      </p>
    </Layout>
  )
}

export default IndexPage
