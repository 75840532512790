import { jsx, css, Themed, Box, Link } from "theme-ui"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as style from './WorkspaceList.module.css'

const WorkspaceList = () => {
  const data = useStaticQuery(graphql`
  query AllWorkspaceQuery {
    allWorkspace {
      edges {
        node {
          name
          id
        }
      }
    }
  }
  `)

  return (
    <Box>
      {
        data.allWorkspace.edges.map(edge =>
          <Link className={style.workspaceListItem} key={edge.node.id} href={`/w/${edge.node.id}`}>
            {edge.node.name}
          </Link>
        )
      }
    </Box>
  )
}

export default WorkspaceList